import React, { FormEvent } from 'react'
import classNames from 'classnames'
import { functions } from '../firebase'
import { avatars } from '../avatars'
import { Room } from '../types';

interface IdentityChooserViewProps {
    room: Room,
    onPlayerJoined: (name: string) => any,
    leaveRoom: () => any,
}

interface IdentityChooserViewState {
    selectedAvatar: string | null,
    nameErrorMessage: string | null,
    isLoading: boolean,
}

export class IdentityChooserView extends React.Component<IdentityChooserViewProps, IdentityChooserViewState> {
    private nameInput: React.RefObject<HTMLInputElement>;

    constructor(props: IdentityChooserViewProps) {
        super(props)

        this.nameInput = React.createRef();

        this.setPlayerIdentity = this.setPlayerIdentity.bind(this)

        this.state = {
            selectedAvatar: '',
            nameErrorMessage: null,
            isLoading: false,
        }
        this.selectAvatar = this.selectAvatar.bind(this)
    }

    selectAvatar(avatar: string) {
        this.setState({ selectedAvatar: avatar })
    }

    async setPlayerIdentity(event: FormEvent) {
        event.preventDefault()
        this.setState({ nameErrorMessage: null, isLoading: true })
        const roomId = this.props.room.roomId
        const name = this.nameInput.current!.value
        const avatar = this.state.selectedAvatar
        const setPlayerIdentity = functions.httpsCallable('setPlayerIdentity')
        try {
            const result = await setPlayerIdentity({ roomId, name, avatar })
            if (result.data.success) {
                this.props.onPlayerJoined(name)
            } else {
                if (result.data?.errorScope === 'name') {
                    this.nameInput.current!.parentElement!.scrollIntoView({ behavior: "smooth" })
                    this.setState({ nameErrorMessage: result.data.errorMessage })
                } else {
                    alert(result.data.errorMessage)
                }
            }
        }
        catch (e) {
            alert(e)
        }
        this.setState({ isLoading: false })
    }

    render() {
        const avatarsInUse = this.props.room.identities ? Object.values(this.props.room.identities).map(i => i.avatar) : []
        return (
            <div className="View">
                <div className="Banner">Who are you?</div>
                <form onSubmit={this.setPlayerIdentity}>
                    <div className="Card">
                        <div className="Card-title">Your name</div>
                        <input className={classNames("Card-input", { "Card-input-error": this.state.nameErrorMessage })} disabled={this.state.isLoading} ref={this.nameInput} type="text" maxLength={8} autoComplete="off" placeholder="name" />
                        {
                            this.state.nameErrorMessage && <div className="Card-subtitle-error">{this.state.nameErrorMessage}</div>
                        }
                    </div>
                    <div className="Card">
                        <div className="Card-title">Your icon</div>
                        <div className="Icons-grid" style={{ pointerEvents: this.state.isLoading ? 'none' : 'unset' }}>
                            {
                                Object.entries(avatars).map(([name, image]) => {
                                    const isSelected = name === this.state.selectedAvatar
                                    const isUnavailable = avatarsInUse.includes(name)
                                    const classes = classNames(
                                        "Icon-grid-cell",
                                        { "Icon-grid-cell-selected": isSelected },
                                        { "Icon-grid-cell-unavailable": isUnavailable },
                                    )
                                    return (
                                        <div onClick={() => this.selectAvatar(name)} key={name} className={classes}>
                                            <img alt={name} src={image} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <button type="submit" disabled={this.state.isLoading} className="Button">That's me!</button>
                </form>
            </div >
        )
    }

}

