import React from 'react';
import { functions } from '../firebase'
import { Room, PlayerName, ActionKind, RoundPlayer } from '../types'
import { avatars } from '../avatars';
import { Rules } from '../Rules'

interface PlayingViewProps {
    room: Room,
    currentRoundPlayer: RoundPlayer,
}

interface PlayingViewState {
    isLoading: boolean,
}

export class PlayingView extends React.Component<PlayingViewProps, PlayingViewState> {

    constructor(props: PlayingViewProps) {
        super(props)

        this.setAction = this.setAction.bind(this)

        this.state = {
            isLoading: false,
        }
    }

    async setAction(kind: ActionKind, target: PlayerName | null = null) {
        this.setState({ isLoading: true })
        const setAction = functions.httpsCallable('setPlayerAction')
        const result = await setAction({
            roomId: this.props.room.roomId,
            playerName: this.props.currentRoundPlayer.name,
            actionKind: kind,
            actionTarget: target,
        })
        if (!result.data.success) {
            alert(result.data.errorMessage)
        }
        this.setState({ isLoading: false })
    }

    async startNextRound() {
        const setAction = functions.httpsCallable('startNextRound')
        const result = await setAction({ roomId: this.props.room.roomId })
        if (!result.data.success) {
            alert(result.data.errorMessage)
        }
    }

    render() {
        const isDecided = Boolean(this.props.currentRoundPlayer.action)
        return (
            <div className="View">
                <div className="Banner">Round {this.props.room.currentRound.number + 1}</div>
                {
                    isDecided ? <div className="Card">
                        <div className="Card-body">Waiting for other players</div>
                    </div> :
                        <div className="ActionList">
                            <button disabled={this.state.isLoading} className="Button Button-action Button-heal" onClick={() => this.setAction('heal')}>HEAL</button>
                            {Object.values(this.props.room.identities).map(identity => {
                                if (identity.name === this.props.currentRoundPlayer.name) return null;
                                return (
                                    <button disabled={this.state.isLoading} className="Button Button-action Button-steal" onClick={() => this.setAction('steal', identity.name)} key={identity.name}
                                    >STEAL
                                    <div className="Identity-chip">
                                            <img alt={identity.avatar} src={avatars[identity.avatar]} />
                                            <div className="Identity-chip-label">{identity.name}</div>
                                        </div>
                                    </button>
                                )
                            })}
                            <button disabled={this.state.isLoading} className="Button Button-action Button-reverse" onClick={() => this.setAction('reverse')}>REVERSE</button>
                        </div>
                }
                <Rules />
            </div>
        )
    }

}
