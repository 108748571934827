import { object } from 'underscore'

const avatarNames = [
    'butterfly',
    'gem',
    'ghost',
    'heart',
    'lipstick',
    'lucky',
    'moon',
    'pizza',
    'poop',
    'recycle',
    'robot',
    'tent',
    'thatface',
    'turtle',
    'unicorn'
]

const avatarImages: string[] = avatarNames.map(name => require(`./images/avatars/${name}.png`))
export const avatars = object(avatarNames, avatarImages) as Record<string, string>