import React, { FormEvent } from 'react'
import classNames from 'classnames'
import { functions } from '../firebase'
import logo from '../images/logo_large.png';

interface RoomSelectorViewProps {
    onValidatedRoomId: (roomId: string) => any
}

interface RoomSelectorViewState {
    errorMessage: string | null,
    isLoading: boolean,
}

export class RoomSelectorView extends React.Component<RoomSelectorViewProps, RoomSelectorViewState> {
    private roomInput: React.RefObject<HTMLInputElement>;

    constructor(props: RoomSelectorViewProps) {
        super(props)

        this.roomInput = React.createRef();

        this.validateRoom = this.validateRoom.bind(this)

        this.state = {
            errorMessage: null,
            isLoading: false,
        }
    }

    async validateRoom(event: FormEvent) {
        event.preventDefault()
        this.setState({ errorMessage: null, isLoading: true })
        const roomId = this.roomInput.current!.value
        const validateRoom = functions.httpsCallable('validateRoom')
        try {
            const result = await validateRoom({ roomId })
            if (result.data.success) {
                this.props.onValidatedRoomId(roomId)
            } else {
                this.setState({ errorMessage: result.data.errorMessage })
            }
        } catch {
            this.setState({ errorMessage: 'yikes! server error. refresh?' })
        }
        this.setState({ isLoading: false })
    }

    render() {
        const inputClasses = classNames(
            "Card-input",
            "Card-input-code",
            { "Card-input-error": this.state.errorMessage }
        )
        return (
            <div className="View">
                <img src={logo} className="App-logo" alt="logo" />
                <form onSubmit={this.validateRoom}>
                    <div className="Card">
                        <div className="Card-title">Room #</div>
                        <input className={inputClasses} disabled={this.state.isLoading} ref={this.roomInput} type="text" pattern="[0-9]*" autoComplete="off" inputMode="numeric" maxLength={4} placeholder="1234" />
                        {
                            this.state.errorMessage && <div className="Card-subtitle-error">{this.state.errorMessage}</div>
                        }
                    </div>
                    <button type="submit" disabled={this.state.isLoading} className="Button Button-jumbo">JOIN</button>
                </form>
            </div>

        )
    }

}

