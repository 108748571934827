import * as firebase from "firebase/app";
import 'firebase/database';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyADTw7vUGwJ8fCgNkWEi1iNby5UEjx0gP0",
    authDomain: "heal-steal.firebaseapp.com",
    databaseURL: "https://heal-steal.firebaseio.com",
    projectId: "heal-steal",
    storageBucket: "heal-steal.appspot.com",
    messagingSenderId: "366844622682",
    appId: "1:366844622682:web:0ddb26609801b1bd766563"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
if (document.location.hostname === 'localhost') {
    firebase.functions().useFunctionsEmulator('http://localhost:5001')
    console.log('using functions emulator')
}
// Get a reference to the database service
export const database = firebase.database();
export const functions = firebase.functions();