import React from 'react';
import { database, functions } from './firebase'

import './App.css';
import './Spinner.css'

import { RoomSelectorView } from './views/RoomSelectorView'
import { IdentityChooserView } from './views/IdentityChooserView'
import { PlayingView } from './views/PlayingView'
import { GameOverView } from './views/GameOverView'
import { WaitingForPlayersView } from './views/WaitingForPlayersView'
import { PlayerName, Room } from './types'

interface AppState {
  room: Room | null,
  name: PlayerName | null,
  isLoading: boolean,
}

class App extends React.Component<{}, AppState> {

  constructor(props: any) {
    super(props)

    this.state = {
      // room: { roomId: '0169' } as Room,
      room: null,
      name: null,
      isLoading: true,
    }

    this.leaveRoom = this.leaveRoom.bind(this)
    this.onPlayerJoined = this.onPlayerJoined.bind(this)
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);

    const savedRoomId = urlParams.get('room') || localStorage.getItem('roomId')
    const savedName = urlParams.get('name') || localStorage.getItem('name')

    let shouldKeepLoading = true
    if (savedRoomId && savedName) {
      const playerRef = database.ref(`rooms/${savedRoomId}/identities/${savedName}`)
      const playerSnapshot = await playerRef.once("value")
      if (playerSnapshot.exists() === true) {
        this.setState({ name: savedName })
        this.onValidatedRoomId(savedRoomId);
        shouldKeepLoading = false
      } else {
        localStorage.removeItem('roomId')
        localStorage.removeItem('name')
      }
    }
    if (shouldKeepLoading) {
      this.setState({ isLoading: false })
    }
  }

  async componentDidUpdate(prevProps: any, prevState: AppState) {
    if (this.state.room?.roomId !== prevState.room?.roomId && this.state.room) {
      localStorage.setItem('roomId', this.state.room.roomId)
    }
    if (this.state.name !== prevState.name && this.state.name) {
      localStorage.setItem('name', this.state.name)
    }
  }

  async leaveRoom() {
    const result = await functions.httpsCallable('leaveRoom')({
      roomId: this.state.room?.roomId,
      name: this.state.name,
    })
    this.setState({ room: null, name: null });
    if (!result.data.success) {
      alert(result.data.errorMessage)
    }
    database.ref(`rooms/${this.state.room?.roomId}`).off('value', this.onRoomValueChanged.bind(this))
  }

  onValidatedRoomId(roomId: string) {
    database.ref(`rooms/${roomId}`).on('value', this.onRoomValueChanged.bind(this))
  }

  onPlayerJoined(name: string) {
    this.setState({ name })
  }

  onRoomValueChanged(roomSnapshot: firebase.database.DataSnapshot) {
    this.setState({
      room: roomSnapshot.val(),
      isLoading: false,
    })
  }

  render() {
    let view = null

    if (this.state.isLoading) {
      view = (
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      )
    }
    else if (!this.state.room) {
      view = (
        <RoomSelectorView onValidatedRoomId={this.onValidatedRoomId.bind(this)} />
      )
    } else if (!this.state.name) {
      view = (
        <IdentityChooserView room={this.state.room} onPlayerJoined={this.onPlayerJoined} leaveRoom={this.leaveRoom} />
      )
    } else if (this.state.room.state === 'Joining') {
      view = (
        <WaitingForPlayersView room={this.state.room} leaveRoom={this.leaveRoom} name={this.state.name} />
      )
    } else if (this.state.room.state === 'Playing') {
      const currentRoundPlayer = this.state.room.currentRound.players[this.state.name]
      view = (
        <PlayingView room={this.state.room} currentRoundPlayer={currentRoundPlayer} />
      )
    } else if (this.state.room.state === 'Ended') {
      view = (
        <GameOverView room={this.state.room} leaveRoom={this.leaveRoom} />
      )
    }

    return (
      <div className="App">
        {view}
      </div>
    );
  }
}

export default App;
