import React from 'react';
import { functions } from '../firebase'
import { Room } from '../types'

interface GameOverViewProps {
    room: Room,
    leaveRoom: () => any,
}

interface GameOverViewState {
    room: Room | null,
    isLoading: boolean,
}

export class GameOverView extends React.Component<GameOverViewProps, GameOverViewState> {

    constructor(props: GameOverViewProps) {
        super(props)

        this.state = {
            room: null,
            isLoading: false,
        }

        this.startGame = this.startGame.bind(this)
        this.leaveRoom = this.leaveRoom.bind(this)
    }

    leaveRoom() {
        this.setState({ isLoading: true })
        this.props.leaveRoom()
    }

    async startGame() {
        this.setState({ isLoading: true })
        const startGame = functions.httpsCallable('startGame')
        const result = await startGame({ roomId: this.props.room.roomId })
        if (!result.data.success) {
            this.setState({ isLoading: false })
            alert(result.data.errorMessage)
        }
    }

    render() {
        return (
            <div className="View">
                <div className="Banner">Game over!</div>
                <button className="Button" style={{ marginTop: 40, marginBottom: 15 }} disabled={this.state.isLoading} onClick={this.startGame}>Rematch</button>
                <button className="Button Button-steal" disabled={this.state.isLoading} onClick={this.leaveRoom}>Leave Room</button>
            </div>
        )
    }

}
