import React from 'react';
import { functions } from '../firebase'
import { Room, PlayerName } from '../types'
import { Rules } from '../Rules'

interface WaitingForPlayersViewProps {
    room: Room,
    name: PlayerName,
    leaveRoom: () => any,
}

interface WaitingForPlayersViewState {
    room: Room | null,
    isLoading: boolean,
}

export class WaitingForPlayersView extends React.Component<WaitingForPlayersViewProps, WaitingForPlayersViewState> {

    constructor(props: WaitingForPlayersViewProps) {
        super(props)

        this.state = {
            room: null,
            isLoading: false,
        }

        this.startGame = this.startGame.bind(this)
        this.leaveRoom = this.leaveRoom.bind(this)
    }

    leaveRoom() {
        this.setState({ isLoading: true })
        if (window.confirm("Are you sure you want to leave the room? You can rejoin if you change your mind.") === false) {
            this.setState({ isLoading: false })
            return
        }
        this.props.leaveRoom()
    }

    async startGame() {
        this.setState({ isLoading: true })
        const setAction = functions.httpsCallable('startGame')
        const result = await setAction({ roomId: this.props.room.roomId })
        if (!result.data.success) {
            this.setState({ isLoading: false })
            alert(result.data.errorMessage)
        }
    }

    render() {
        const enoughPlayersToStart = this.props.room.identities && Object.values(this.props.room.identities).length >= 2
        return (
            <div className="View">
                <div className="Banner">Get ready, {this.props.name} </div>

                <div className="Card">
                    <div className="Card-body">{
                        enoughPlayersToStart ?
                            <span>Press <strong>START GAME</strong> once all players have joined the room</span>
                            : <span>Waiting for more players to join</span>
                    }</div>
                </div>
                <button className="Button" disabled={!enoughPlayersToStart || this.state.isLoading} onClick={this.startGame}>Start Game</button>
                <Rules />
                <button className="Button Button-minor" disabled={this.state.isLoading} onClick={this.leaveRoom}>Leave Room</button>
            </div>
        )
    }

}
